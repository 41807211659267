body {
  font-family: 'Roboto', sans-serif;
  overflow-x: hidden;
}

/* Basic */

input,
select,
textarea {
  outline: none;
}

a:hover {
  color: #fc3;
  text-decoration: none;
}

p {
  font-size: 14px;
  line-height: 25px;
  color: #7a7a7a;
  letter-spacing: 0.2px;
}

h4 {
  font-size: 19px;
  font-weight: 700;
  color: #31323a;
  letter-spacing: 0.25px;
}

img {
  overflow: hidden;
}

.primary-button a,
button {
  font-size: 14px;
  font-weight: 700;
  text-transform: uppercase;
  background-color: transparent;
  display: inline-block;
  padding: 8px 20px;
  color: #535ba0;
  border: 2px solid #535ba0;
  transition: all 0.5s;
}
.primary-button a:hover,
button:hover {
  color: #fff;
  background-color: rgba(83, 91, 160, 1);
}

section#search {
  border-bottom: none;
  padding-bottom: 0px;
}

section {
}

/* Wrapper */

#wrapper {
  display: -moz-flex;
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  -moz-flex-direction: row-reverse;
  -webkit-flex-direction: row-reverse;
  -ms-flex-direction: row-reverse;
  flex-direction: row-reverse;
  min-height: 100vh;
}

/* Main */

#main {
  -moz-flex-grow: 1;
  -webkit-flex-grow: 1;
  -ms-flex-grow: 1;
  flex-grow: 1;
  -moz-flex-shrink: 1;
  -webkit-flex-shrink: 1;
  -ms-flex-shrink: 1;
  flex-shrink: 1;
  width: 100%;
}

.inner {
  padding: 80px;
}
.about_mining {
  padding: 50px;
  margin-top: -80px;
}
.about_mining sup {
  color: #f37622;
  text-transform: uppercase;
  font-size: 20px;
  font-family: open sans;
  display: block;
  line-height: 24px;
}
.about_mining h2 {
  font-size: 34px;
  text-transform: uppercase;
  color: #175480;
  font-family: open sans;
  font-weight: 600;
}
.about_mining p {
  font-size: 15px;
  font-family: Open Sans;
  line-height: 20px;
  font-weight: 500;
  color: #5c5c5c;
}
/* News */
.news {
  margin-top: 50px;
  margin-bottom: 80px;
}
.news .news-item {
  margin: 5px;
  padding: 30px;
  text-align: center;
  transition: all 0.5s;
  min-height: 285px;
}
.news-item h4 {
  color: #29425a;
  font-size: 18px;
  font-family: roboto;
  line-height: 20px;
}
.news-item p {
  color: #a7a7a7;
  font-size: 15px;
  font-family: roboto;
  line-height: 20px;
  margin-top: 12px;
  margin-bottom: 50px;
}
.date_wrapper {
  color: #29425a;
  margin-bottom: 25px;
}
.day {
  font-size: 50px;
  color: #ee7222;
  font-weight: 600;
  line-height: 50px;
}
.mm {
  font-size: 14px;
  color: #ee7222;
  text-transform: uppercase;
  font-weight: 500;
}
.btn_readmore {
  background: #f5f5f6;
  border-radius: 40px;
  padding: 15px 30px;
  color: #29425a;
  font-size: 14px;
  text-transform: uppercase;
  font-weight: 500;
}
.btn_readmore:hover {
  background: #175480;
  color: #fff;
}

.services .service-item:hover h4,
.services .service-item:hover p {
  color: #fff;
}
.services .service-item p {
  margin-bottom: 0px;
  transition: all 0.5s;
  font-size: 15px;
  font-family: Open Sans;
  line-height: 20px;
  font-weight: 500;
}
/* Banner */
.main-banner .container-fluid {
  padding-left: 0px;
  padding-right: 0px;
}
.main-banner .banner-content {
  background-image: url(../images/main-banner.jpg);
  background-size: cover;
  background-repeat: no-repeat;
}

.main-banner .banner-content .banner-caption {
  max-width: 450px;
  background-color: rgba(250, 250, 250, 0.95);
  margin: 50px;
  padding: 50px;
}

.main-banner .banner-content .banner-caption h4 {
  font-size: 38px;
  font-weight: 700;
  color: #31323a;
  letter-spacing: 0.25px;
  margin: 0px;
}

.main-banner .banner-content .banner-caption em {
  font-style: normal;
  color: #535ba0;
}

.main-banner .banner-content .banner-caption span {
  font-size: 15px;
  color: #aaaaaa;
  letter-spacing: 0.5px;
  display: inline-block;
  margin-top: 20px;
  margin-bottom: 20px;
}

.main-banner .banner-content .banner-caption p {
  margin-bottom: 30px;
}

/* Services */

.services {
  margin-top: 50px;
  margin-bottom: 80px;
}

.services img {
  width: 102px;
  height: 102px;
}
.services .container-fluid {
  padding-left: 13px;
  padding-right: 13px;
}

.services .col-md-4 {
  padding-left: 2px;
  padding-right: 2px;
}

.services .service-item {
  margin: 5px;
  background-color: #efefef;
  padding: 30px;
  text-align: center;
  transition: all 0.5s;
  min-height: 285px;
}

.services .service-item .icon {
  width: 50px;
  height: 50px;
  display: inline-block;
  margin-bottom: 30px;
  background-size: cover;
  background-repeat: no-repeat;
  transition: all 0.5s;
}

.services .service-item:hover {
  background-color: #175480;
}
.services .service-item:hover h4,
.services .service-item:hover p {
  color: #fff;
}

.services .service-item h4 {
  margin-bottom: 10px;
  transition: all 0.5s;
  font-size: 17px;
  font-family: Open Sans;
  line-height: 24px;
  text-transform: uppercase;
  font-weight: 600;
}

.services .service-item p {
  margin-bottom: 0px;
  transition: all 0.5s;
  font-size: 15px;
  font-family: Open Sans;
  line-height: 20px;
  font-weight: 500;
}

/* Mining Pool */

.mining_pool {
  margin-top: 50px;
  margin-bottom: 80px;
}

.mining_pool img {
  width: 102px;
  height: 102px;
}
.mining_pool .container-fluid {
  padding-left: 13px;
  padding-right: 13px;
}

.mining_pool .col-md-4 {
  padding-left: 2px;
  padding-right: 2px;
}

.mining_pool .service-item {
  margin: 8px 13px 30px;
  background-color: #fafafa;
  padding: 30px;
  text-align: center;
  box-shadow: 0px 0px 10px 1px rgba(0, 0, 0, 0.07);
  -webkit-box-shadow: 0px 0px 10px 1px rgba(0, 0, 0, 0.07);
  -moz-box-shadow: 0px 0px 10px 1px rgba(0, 0, 0, 0.07);
}

.mining_pool .service-item .icon {
  width: 50px;
  height: 50px;
  display: inline-block;
  margin-bottom: 30px;
  background-size: cover;
  background-repeat: no-repeat;
  transition: all 0.5s;
}

.mining_pool .service-item h4 {
  margin-bottom: 10px;
  transition: all 0.5s;
  font-size: 17px;
  font-family: Open Sans;
  line-height: 24px;
  color: #175480;
  text-transform: uppercase;
  font-weight: 400;
}

.mining_pool .service-item p {
  margin-bottom: 0px;
  transition: all 0.5s;
  font-size: 15px;
  font-family: Open Sans;
  line-height: 20px;
  color: #212121;
  font-weight: 500;
}

/* Top Image */

.top-image {
  margin-top: 100px;
}

.top-image .container-fluid {
  padding-left: 0px;
  padding-right: 0px;
}

.top-image h4 {
  margin: 30px 0px 20px 0px;
}

.top-image .primary-button {
  margin-top: 30px;
}

/* Left Image */
.left-image {
  margin-top: 100px;
}

.left-image .container-fluid {
  padding-left: 0px;
  padding-right: 0px;
}

.left-image .right-content {
  margin-left: 15px;
}

.left-image h4 {
  margin: 0px 0px 20px 0px;
}

.left-image .primary-button {
  margin-top: 30px;
}

/* Right Image */
section.right-image {
  border-bottom: none;
  padding-bottom: 0px;
}

.right-image {
  margin-top: 100px;
}

.right-image .container-fluid {
  padding-left: 0px;
  padding-right: 0px;
}

.right-image .left-content {
  margin-right: 15px;
}

.right-image h4 {
  margin: 0px 0px 20px 0px;
}

.right-image .primary-button {
  margin-top: 30px;
}

/* Simple Post */
.simple-post {
  margin-top: 80px;
}

.simple-post .container-fluid {
  padding-left: 0px;
  padding-right: 0px;
}

.simple-post p {
  margin-top: 30px;
  margin-bottom: 0px;
}

/* Contact Form */

section.contact-form {
  padding-bottom: 0px;
  border-bottom: none;
}

.contact-form {
  margin-top: 100px;
}

.contact-form .container-fluid {
  padding-left: 0px;
  padding-right: 0px;
}

.contact-form input {
  font-size: 14px;
  color: #7a7a7a;
  width: 100%;
  height: 50px;
  border-radius: 0px;
  border: 2px solid #eee;
  outline: none;
  padding-left: 20px;
  line-height: 50px;
  margin-bottom: 40px;
}

.contact-form input:focus {
  outline: none;
  box-shadow: none;
  border-color: #535ba0;
}

.contact-form textarea {
  font-size: 14px;
  color: #7a7a7a;
  width: 100%;
  max-width: 100%;
  height: 160px;
  max-height: 220px;
  border-radius: 0px;
  border: 2px solid #eee;
  outline: none;
  padding-left: 20px;
  line-height: 50px;
  margin-bottom: 40px;
}

.contact-form textarea:focus {
  outline: none;
  box-shadow: none;
  border-color: #535ba0;
}

.contact-form button {
  font-size: 14px;
  font-weight: 700;
  text-transform: uppercase;
  display: inline-block;
  padding: 8px 20px;
  color: #535ba0;
  background-color: transparent;
  border: 2px solid #535ba0;
  transition: all 0.5s;
  cursor: pointer;
}

.contact-form button:hover {
  color: #fff;
  background-color: rgba(83, 91, 160, 1);
}

/* Tables */

section.tables {
  margin-top: 80px;
  border-top: 3px solid #eee !important;
  padding-top: 100px;
}

section.tables .container-fluid {
  padding-left: 0px;
  padding-right: 0px;
}

.default-table {
  -webkit-overflow-scrolling: touch;
  overflow-x: auto;
  background-color: #f7f7f7;
  padding: 50px;
}

.alternate-table {
  margin-top: 50px;
  -webkit-overflow-scrolling: touch;
  overflow-x: auto;
  background-color: #f7f7f7;
  padding: 50px;
}

.alternate-table table td {
  border-right: 2px solid #f7f7f7;
}

.alternate-table table td:last-child {
  border-right: none;
}

table {
  width: 100%;
}

table tbody tr {
  border-left: 0;
  border-right: 0;
  border-bottom: 10px solid #f7f7f7;
}

table td {
  padding: 20px;
}

table th {
  padding: 0px 20px 20px 20px;
  font-size: 17px;
  font-weight: 700;
  letter-spacing: 0.5px;
  text-align: left;
  text-transform: capitalize;
}

@media screen and (max-width: 980px) {
  table th {
    font-size: 15px;
  }
  .inner {
    padding: 20px;
    margin-top: 80px;
  }
}

table.alt {
  border-collapse: separate;
}

table.alt tbody tr td {
  border: solid 1px;
  border-left-width: 0;
  border-top-width: 0;
}

table.alt tbody tr td:first-child {
  border-left-width: 1px;
}

table.alt tbody tr:first-child td {
  border-top-width: 1px;
}

table.alt thead {
  border-bottom: 0;
}

table.alt tfoot {
  border-top: 0;
}

table tbody tr {
  background-color: #e7e7e7;
}

table tbody tr:nth-child(2n + 1) {
  background-color: #fff;
}

table th {
  color: #4a4a4a;
}

ul.table-pagination {
  padding: 0;
  margin-top: 30px;
  list-style: none;
  text-align: center;
}

ul.table-pagination li {
  display: inline-block;
}

ul.table-pagination li:first-child {
  float: left;
}

ul.table-pagination li:last-child {
  float: right;
}

ul.table-pagination li a {
  display: inline-block;
  height: 40px;
  background-color: #e7e7e7;
  text-align: center;
  line-height: 40px;
  padding: 0px 15px;
  min-width: 40px;
  color: #4a4a4a;
  text-transform: uppercase;
  font-size: 13px;
  transition: all 0.5s;
}

ul.table-pagination li a:hover,
ul.table-pagination li.active a {
  background-color: #535ba0;
  color: #fff;
}

/* Forms */

section.forms {
  margin-top: 90px;
}

section.forms .container-fluid {
  padding-left: 0px;
  padding-right: 0px;
}

.forms input,
.forms select {
  font-size: 14px;
  color: #7a7a7a;
  width: 100%;
  height: 50px;
  border-radius: 0px;
  border: 2px solid #eee;
  outline: none;
  padding-left: 20px;
  line-height: 50px;
  margin-bottom: 40px;
}

.forms textarea {
  font-size: 14px;
  color: #7a7a7a;
  width: 100%;
  height: 160px;
  max-height: 220px;
  border-radius: 0px;
  border: 2px solid #eee;
  outline: none;
  padding-left: 20px;
  line-height: 50px;
  margin-bottom: 40px;
}

.forms input:focus,
.forms select:focus,
.forms textarea:focus {
  outline: none;
  box-shadow: none;
  border-color: #535ba0;
}

.forms .radio-item,
.forms .circle-item {
  margin-bottom: 30px;
}

input[type='checkbox'],
input[type='radio'] {
  display: block;
  float: left;
  margin-top: 3px;
}

input[type='checkbox'] + label,
input[type='radio'] + label {
  text-decoration: none;
  cursor: pointer;
  display: inline-block;
  font-size: 14px;
  letter-spacing: 0;
  text-transform: none;
  font-weight: 300;
  background-color: #eee;
  padding: 20px;
  padding-right: 1rem;
  position: relative;
  min-width: 180px;
  width: 100%;
  display: inline-block;
  line-height: 22px;
}

input[type='checkbox'] + label:before,
input[type='radio'] + label:before {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: FontAwesome;
  font-style: normal;
  font-weight: normal;
  text-transform: none !important;
}

input[type='checkbox'] + label:before,
input[type='radio'] + label:before {
  border-radius: 0px;
  background-color: #fff;
  content: '';
  display: inline-block;
  line-height: 30px;
  position: absolute;
  text-align: center;
  width: 30px;
  height: 30px;
  top: 50%;
  transform: translateY(-50%);
  right: 10px;
}

.circle-item input[type='checkbox'] + label:before,
.circle-item input[type='radio'] + label:before {
  border-radius: 50%;
}

input[type='checkbox']:checked + label:before,
input[type='radio']:checked + label:before {
  content: '\f00c';
  background-color: #535ba0;
  color: #fff;
}

input[type='checkbox'] + label:before {
  border-radius: 0;
}

input[type='radio'] + label:before {
  border-radius: 0px;
}

/* Buttons */
section.buttons {
  margin-top: 90px;
  border-bottom: none;
  padding-bottom: 0px;
}

section.buttons .container-fluid {
  padding-left: 0px;
  padding-right: 0px;
}

.filled-rectangle-button {
  margin-bottom: 30px;
}
.filled-rectangle-button a {
  font-size: 14px;
  font-weight: 700;
  text-transform: uppercase;
  display: inline-block;
  padding: 8px;
  width: 100%;
  text-align: center;
  letter-spacing: 0.5px;
  color: #fff;
  border: 2px solid #535ba0;
  transition: all 0.5s;
  background-color: rgba(83, 91, 160, 1);
}
.filled-rectangle-button a:hover {
  color: #535ba0;
  background-color: rgba(83, 91, 160, 0.25);
}

.border-rectangle-button {
  margin-bottom: 30px;
}
.border-rectangle-button a {
  font-size: 14px;
  font-weight: 700;
  text-transform: uppercase;
  display: inline-block;
  padding: 8px;
  width: 100%;
  text-align: center;
  letter-spacing: 0.5px;
  color: #535ba0;
  border: 2px solid #535ba0;
  transition: all 0.5s;
  background-color: rgba(83, 91, 160, 0);
}
.border-rectangle-button a:hover {
  color: #fff;
  background-color: rgba(83, 91, 160, 1);
}

.filled-radius-button {
  margin-bottom: 30px;
}
.filled-radius-button a {
  font-size: 14px;
  font-weight: 700;
  text-transform: uppercase;
  display: inline-block;
  padding: 8px;
  width: 100%;
  text-align: center;
  letter-spacing: 0.5px;
  color: #fff;
  border: 2px solid #535ba0;
  transition: all 0.5s;
  background-color: rgba(83, 91, 160, 1);
  border-radius: 5px;
}
.filled-radius-button a:hover {
  color: #fff;
  background-color: rgba(83, 91, 160, 0.25);
}

.border-radius-button {
  margin-bottom: 30px;
}
.border-radius-button a {
  font-size: 14px;
  font-weight: 700;
  text-transform: uppercase;
  display: inline-block;
  padding: 8px;
  width: 100%;
  text-align: center;
  letter-spacing: 0.5px;
  color: #535ba0;
  border: 2px solid #535ba0;
  transition: all 0.5s;
  background-color: rgba(83, 91, 160, 0);
  border-radius: 5px;
}
.border-radius-button a:hover {
  color: #fff;
  background-color: rgba(83, 91, 160, 1);
}

.filled-rounded-button {
  margin-bottom: 30px;
}
.filled-rounded-button a {
  font-size: 14px;
  font-weight: 700;
  text-transform: uppercase;
  display: inline-block;
  padding: 8px;
  width: 100%;
  text-align: center;
  letter-spacing: 0.5px;
  color: #fff;
  border: 2px solid #535ba0;
  transition: all 0.5s;
  background-color: rgba(83, 91, 160, 1);
  border-radius: 20px;
}
.filled-rounded-button a:hover {
  color: #fff;
  background-color: rgba(83, 91, 160, 0.25);
}

.border-rounded-button {
  margin-bottom: 30px;
}
.border-rounded-button a {
  font-size: 14px;
  font-weight: 700;
  text-transform: uppercase;
  display: inline-block;
  padding: 8px;
  width: 100%;
  text-align: center;
  letter-spacing: 0.5px;
  color: #535ba0;
  border: 2px solid #535ba0;
  transition: all 0.5s;
  background-color: rgba(83, 91, 160, 0);
  border-radius: 20px;
}
.border-rounded-button a:hover {
  color: #fff;
  background-color: rgba(83, 91, 160, 1);
}

.filled-icon-button {
  margin-bottom: 30px;
}
.filled-icon-button i {
  margin-right: 8px;
}
.filled-icon-button a {
  font-size: 14px;
  font-weight: 700;
  text-transform: uppercase;
  display: inline-block;
  padding: 8px;
  width: 100%;
  text-align: center;
  letter-spacing: 0.5px;
  color: #fff;
  border: 2px solid #535ba0;
  transition: all 0.5s;
  background-color: rgba(83, 91, 160, 1);
  border-radius: 20px;
}
.filled-icon-button a:hover {
  color: #fff;
  background-color: rgba(83, 91, 160, 0.25);
}

.border-icon-button {
  margin-bottom: 30px;
}
.border-icon-button i {
  margin-right: 8px;
}
.border-icon-button a {
  font-size: 14px;
  font-weight: 700;
  text-transform: uppercase;
  display: inline-block;
  padding: 8px;
  width: 100%;
  text-align: center;
  letter-spacing: 0.5px;
  color: #535ba0;
  border: 2px solid #535ba0;
  transition: all 0.5s;
  background-color: rgba(83, 91, 160, 0);
  border-radius: 20px;
}
.border-icon-button a:hover {
  color: #fff;
  background-color: rgba(83, 91, 160, 1);
}

/* Page Heading */

.page-heading .container-fluid {
  padding-left: 0px;
  padding-right: 0px;
}

.page-heading h1 {
  margin-top: 0px;
  font-size: 38px;
  font-weight: 700;
  margin-bottom: 20px;
}

.page-heading p {
  font-size: 15px;
  color: #4a4a4a;
}

/* Section Heading */

.section-heading .container-fluid {
  padding-left: 0px;
  padding-right: 0px;
}

.section-heading h2 {
  margin-top: 0px;
  font-size: 24px;
  font-weight: 700;
  margin-bottom: 50px;
}

/* Sidebar */

#sidebar {
  -moz-flex-grow: 0;
  -webkit-flex-grow: 0;
  -ms-flex-grow: 0;
  flex-grow: 0;
  -moz-flex-shrink: 0;
  -webkit-flex-shrink: 0;
  -ms-flex-shrink: 0;
  flex-shrink: 0;
  -moz-transition: margin-left 0.5s ease, box-shadow 0.5s ease;
  -webkit-transition: margin-left 0.5s ease, box-shadow 0.5s ease;
  -ms-transition: margin-left 0.5s ease, box-shadow 0.5s ease;
  transition: margin-left 0.5s ease, box-shadow 0.5s ease;
  background-color: #175480;
  position: relative;
  width: 290px;
}

#sidebar > .inner {
  position: relative;
  padding: 0px;
}

#sidebar .toggle {
  text-decoration: none;
  -moz-transition: left 0.5s ease;
  -webkit-transition: left 0.5s ease;
  -ms-transition: left 0.5s ease;
  transition: left 0.5s ease;
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
  border: 0;
  display: block;
  color: #000;
  height: 100px;
  left: 250px;
  line-height: 100px;
  outline: 0;
  overflow: hidden;
  position: absolute;
  text-align: center;
  text-indent: 100px;
  top: 0;
  width: 100px;
  z-index: 10000;
}

#sidebar .toggle:before {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: Material Icons;
  font-style: normal;
  font-weight: normal;
  text-transform: none !important;
}

#sidebar .toggle:before {
  content: '\e5d2';
  font-size: 2rem;
  height: inherit;
  left: 0;
  line-height: inherit;
  position: absolute;
  text-indent: 0;
  top: 0;
  width: inherit;
}

#sidebar.inactive {
  margin-left: 0px;
}
/* Mobile Banner */
.show_banner {
  display: none;
}
/* Mobile HashRates */
.show_hashrates {
  display: none;
}
@media screen and (max-width: 1680px) {
  #sidebar {
    width: 290px;
  }
  #sidebar > .inner {
    padding: 0px;
    width: 290px;
    background-color: #175480 !important;
  }
  #sidebar .toggle {
    height: 100px;
    line-height: 80px;
    color: #000;
  }
  #sidebar .toggle:before {
    font-size: 24px;
    font-weight: 300;
  }
  #sidebar.inactive {
    margin-left: -280px;
  }

  #menu {
    margin-top: 30px;
    margin-left: 30px;
    margin-right: 30px;
  }
  .featured-posts {
    margin-left: -30px !important;
    margin-right: -30px !important;
    padding: 30px !important;
  }
}

@media screen and (max-width: 1280px) {
  #sidebar {
    box-shadow: 0 0 5em 0 rgba(0, 0, 0, 0.175);
    height: 100%;
    left: 0;
    position: fixed;
    top: 0;
    z-index: 10000;
  }
  #sidebar.inactive {
    box-shadow: none;
  }
  #sidebar > .inner {
    -webkit-overflow-scrolling: touch;
    height: 100%;
    left: 0;
    overflow-x: hidden;
    overflow-y: auto;
    position: absolute;
    top: 0;
  }
  #sidebar > .inner:after {
    content: '';
    display: block;
    height: 4em;
    width: 100%;
  }
  #sidebar .toggle {
    width: 6em;
  }
  #sidebar .toggle:before {
    font-size: 1.5rem;
    color: #fff;
    background-color: #f17421;
    width: 55px;
    height: 77px;
    left: 40px;
  }
}

@media screen and (max-width: 1050px) {
  .top_stats {
    width: 100% !important;
    padding-left: 70px !important;
  }
  .top_stats .row {
    text-align: left !important;
  }
}
@media screen and (max-width: 950px) {
  .slider1 {
    background-size: contain !important;
    background-position: right !important;
    height: 500px !important;
  }
  .slider2 {
    background-size: contain !important;
    background-position: right !important;
    height: 500px !important;
  }
  .slider3 {
    background-size: contain !important;
    background-position: right !important;
    height: 500px !important;
  }
  .about_mining {
    padding: 20px;
  }
  .about_mining .col-md-6 {
    max-width: 100% !important;
    flex: auto !important;
    text-align: center;
  }
  #MiddleCarousel .carousel-indicators {
    margin-bottom: 20px !important;
  }
}

@media screen and (max-width: 845px) {
  .logo a {
    margin-right: 30px !important;
  }
  #main > .menu {
    padding: 15px;
  }
  #header {
  }

  .page-heading h1 {
    margin-top: 80px;
  }
  #sidebar .toggle {
    text-indent: 8.25em;
    width: 7.25em;
  }
  #sidebar .toggle:before {
    color: #fff;
    margin-left: -0.0855em;
    margin-top: -0.25em;
    font-size: 20px;
    z-index: 1;
  }

  .left-image .right-content {
    margin-top: 40px;
    margin-left: 0px;
  }
  .right-image .left-content {
    margin-bottom: 40px;
    margin-right: 0px;
  }
  .main-banner .banner-content .banner-caption {
    padding: 30px;
  }
  .contact-form button {
    margin-bottom: 30px;
  }
  .slider1 {
    background-size: contain !important;
    background-position: right !important;
    height: 300px !important;
  }
  .slider2 {
    background-size: contain !important;
    background-position: right !important;
    height: 300px !important;
  }
  .slider3 {
    background-size: contain !important;
    background-position: right !important;
    height: 300px !important;
  }
  .content_wrapper h1 {
    font-size: 30px !important;
    line-height: normal !important;
  }
  .content_wrapper h2 {
    font-size: 25px !important;
  }
  .content_wrapper {
    padding: 50px 40px !important;
  }
  .content_slide3 span {
    font-size: 20px !important;
    line-height: normal !important;
  }
  .top_stats .row {
    text-align: center !important;
    line-height: 30px !important;
  }
}

@media screen and (max-width: 700px) {
  .slider1 {
    background-size: contain !important;
    background-position: right !important;
    height: 300px !important;
  }
  .slider2 {
    background-size: contain !important;
    background-position: right !important;
    height: 300px !important;
  }
  .slider3 {
    background-size: contain !important;
    background-position: right !important;
    height: 300px !important;
  }
  .content_wrapper h1 {
    font-size: 20px !important;
    line-height: normal !important;
  }
  .content_wrapper h2 {
    font-size: 20px !important;
  }
  .content_wrapper {
    padding: 30px 20px !important;
  }
  .content_slide3 span {
    font-size: 15px !important;
    line-height: normal !important;
    padding-top: 15px !important;
  }
  .content_slide3 img {
    width: 70px !important;
  }
  .content_slide3 p {
    font-size: 12px !important;
  }
  .content_wrapper li {
    font-size: 15px !important;
  }
}

@media screen and (max-width: 674px) {
  .hide_logo {
    display: none !important;
  }
  .hide_hashrates {
    display: none;
  }
  .show_hashrates {
    display: block;
  }
  .hide_slider {
    display: none;
  }
  .show_banner {
    display: block;
    padding: 30px 0px 0px 0px;
    background-image: url(../images/banner.jpg);
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    min-height: 250px;
  }
  .show_banner h1 {
    font-size: 48px;
    text-align: center;
    color: #fff;
    text-transform: uppercase;
    font-family: roboto;
    margin-bottom: 0px;
    line-height: 48px;
  }
  .show_banner h2 {
    font-size: 30px;
    text-align: center;
    color: #fff;
    text-transform: uppercase;
    font-family: roboto;
  }
  .show_banner p {
    text-align: center;
    color: #fff;
    font-size: 15px;
    font-family: roboto;
    line-height: 20px;
  }
  .hashrates {
    background-color: #0f466e;
    opacity: 0.8;
    padding: 20px 30px 20px 30px !important;
  }

  .hashrates table tbody tr:nth-child(2n + 1) {
    background-color: inherit;
  }
  .hashrates table tbody tr {
    border-bottom: 0px;
  }
  .hashrates table td {
    padding: 0px;
  }
  .hashrates {
    text-align: center;
    color: #fff;
  }
  .values span {
    clear: both;
    display: block;
    color: #fe934c;
    font-weight: bold;
  }
}

@media screen and (max-width: 576px) {
  .slider1 {
    background-size: contain !important;
    background-position: right !important;
    height: 200px !important;
  }
  .slider2 {
    background-size: contain !important;
    background-position: right !important;
    height: 200px !important;
  }
  .slider3 {
    background-size: contain !important;
    background-position: right !important;
    height: 200px !important;
  }
  .about_mining img {
    width: 100%;
  }
}

@media screen and (max-width: 500px) {
  .slider1 {
    background-image: none !important;
    height: 200px !important;
  }
  .slider2 {
    background-image: none !important;
    height: 200px !important;
  }
  .slider3 {
    background-image: none !important;
    height: 200px !important;
  }
}

@media screen and (max-width: 450px) {
  .content_wrapper li {
    line-height: normal !important;
    background-position: top left !important;
    margin-bottom: 20px !important;
  }
  .content_slide3 img {
    width: 50px !important;
  }
  .content_slide3 span {
    font-size: 15px !important;
    padding-top: 5px !important;
  }
  .content_slide3 p {
    font-size: 11px !important;
  }
  .show_banner h1 {
    font-size: 40px;
  }
  .show_banner h2 {
    font-size: 25px;
  }
}

@media screen and (max-width: 370px) {
  .show_banner h1 {
    font-size: 30px;
    line-height: 30px;
  }
  .show_banner h2 {
    font-size: 20px;
  }
  .hashrates {
    font-size: 14px;
  }
}

/* Featured Post */

.featured-posts {
  margin-top: 50px;
  position: relative;
  background-color: #1e1f25;
  margin-left: -50px;
  margin-right: -50px;
  padding: 50px;
}

.featured-posts .heading h2 {
  font-size: 19px;
  color: #fff;
  border-bottom: 1px solid #2d2e39;
  padding-bottom: 10px;
  margin-bottom: 25px;
}

.featured-posts p {
  margin-top: 20px;
  color: #fff;
}

.owl-carousel .owl-dots {
  position: absolute;
  top: -55px;
  right: 0;
}

.owl-carousel button.owl-dot {
  width: 10px;
  height: 10px;
  background-color: #2d2e39 !important;
  margin-left: 5px;
  outline: none;
}

.owl-carousel button.active {
  background-color: #535ba0 !important;
}

/* Header */

#header {
  display: -moz-flex;
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  position: relative;
  background-color: #535ba0;
  /*   margin: -100px -100px 100px -100px; */
}

.logo {
  text-align: right;
  display: inline-block;
  width: 100%;
  line-height: 10px;
  background-color: #fff;
}
.logo img {
  width: 260px;
  height: 60px;
  margin-left: auto;
  margin-right: auto;
  display: block;
}
.logo a {
  font-size: 36px;
  text-transform: uppercase;
  letter-spacing: 1px;
  color: #fff;
  font-weight: 700;
  margin-right: 100px;
}

/* buttons */
.btn_blue {
  background: #175480;
  font-size: 16px;
  font-family: roboto;
  text-transform: uppercase;
  padding: 9px 18px;
  line-height: 45px;
  border-radius: 25px;
  font-weight: 500;
  color: #fff !important;
  margin-right: 12px;
}
.btn_blue:hover {
  background: #205e8b;
}
.btn_orange {
  background: #f37622;
  font-size: 16px;
  font-family: roboto;
  text-transform: uppercase;
  padding: 9px 18px;
  line-height: 45px;
  border-radius: 25px;
  font-weight: 500;
  color: #fff !important;
}
.btn_orange:hover {
  background: #f57d2c;
}

/* custom headings */
.headings {
  text-align: center;
}
.headings p {
  color: #212121;
  font-family: roboto;
  font-size: 17px;
  font-weight: 300;
}
.headings h2 {
  font-size: 48px;
  text-transform: uppercase;
  color: #175480;
  font-family: open sans;
  font-weight: 600;
}

.headings h3 {
  font-size: 30px;
  text-transform: uppercase;
  color: #175480;
  font-family: open sans;
  font-weight: 400;
  padding-top: 10px;
}

.headings sup {
  color: #f37622;
  text-transform: uppercase;
  font-size: 20px;
  font-family: open sans;
  display: block;
}

/* top stats */
.top_stats {
  padding-top: 16px;
  padding-bottom: 16px;
}
.top_stats .row {
  line-height: 44px;
  text-align: center;
}
.top_stats span {
  margin-left: 15px;
  font-size: 15px;
}

/* Material Icons */
#menu .material-icons {
  float: left;
  font-size: 25px;
  margin-right: 12px;
}

/* Footer */

#footer .copyright {
  text-align: center;
  color: #fff;
  font-size: 14px;
  margin-top: 50px;
}

#footer .copyright p {
  margin-bottom: 0px;
}

/* Menu */

#menu {
  margin-top: 30px;
  margin-left: 30px;
  margin-right: 30px;
}

#menu ul {
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  color: #3d4449;
  font-family: 'Roboto', serif;
  font-family: 400;
  letter-spacing: 0.075em;
  list-style: none;
  margin-bottom: 0;
  padding: 0;
  text-transform: uppercase;
}

#menu ul a,
#menu ul span {
  color: #fff;
  border-bottom: 0;
  cursor: pointer;
  display: block;
  font-size: 16px;
  font-weight: 400;
  padding: 0px 0px;
  line-height: 45px;
}
#menu ul li ul a,
#menu ul li ul span {
  padding: 10px 0px;
}

#menu ul a:hover,
#menu ul span:hover {
  color: #fff;
}

#menu ul a.opener,
#menu ul span.opener {
  -moz-transition: color 0.2s ease-in-out;
  -webkit-transition: color 0.2s ease-in-out;
  -ms-transition: color 0.2s ease-in-out;
  transition: color 0.2s ease-in-out;
  text-decoration: none;
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
  position: relative;
}

#menu ul a.opener:before,
#menu ul span.opener:before {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: FontAwesome;
  font-style: normal;
  font-weight: normal;
  text-transform: none !important;
}

#menu ul a.opener:before,
#menu ul span.opener:before {
  -moz-transition: color 0.2s ease-in-out, -moz-transform 0.2s ease-in-out;
  -webkit-transition: color 0.2s ease-in-out, -webkit-transform 0.2s ease-in-out;
  -ms-transition: color 0.2s ease-in-out, -ms-transform 0.2s ease-in-out;
  transition: color 0.2s ease-in-out, transform 0.2s ease-in-out;
  color: #fff;
  content: '\f078';
  position: absolute;
  right: 0;
}

#menu ul a.opener:hover:before,
#menu ul span.opener:hover:before {
  color: #535ba0;
}

#menu ul a.opener.active + ul,
#menu ul span.opener.active + ul {
  display: block;
}

#menu ul a.opener.active:before,
#menu ul span.opener.active:before {
  -moz-transform: rotate(-30deg);
  -webkit-transform: rotate(-30deg);
  -ms-transform: rotate(-30deg);
  transform: rotate(-30deg);
}

#menu > ul > li > ul {
  color: #9fa3a6;
  display: none;
  margin: 0.5em 0 1.5em 0;
  padding-left: 1em;
}

#menu > ul > li > ul a,
#menu > ul > li > ul span {
  font-size: 13px;
}

#menu > ul > li > ul > li {
  margin: 0.125em 0 0 0;
  padding: 0.125em 0 0 0;
}

#menu > ul > li:first-child {
  border-top: 0;
  margin-top: 0;
  padding-top: 0;
}

/* Slider */
#MiddleCarousel .carousel-indicators {
  margin-bottom: 70px;
}
.content_wrapper {
  background-color: #e7ecee;
  padding: 50px 40px;
}
.content_wrapper h1 {
  font-size: 60px;
  font-family: roboto;
  color: #175480;
  font-weight: bolder;
  text-transform: uppercase;
  line-height: 50px;
}
.content_wrapper h2 {
  font-size: 50px;
  font-family: roboto;
  color: #f37622;
  font-weight: 500;
  text-transform: uppercase;
}
.content_wrapper ul {
  padding: 0px;
  margin: 0px;
}

.content_wrapper li {
  font-size: 19px;
  color: #212121;
  line-height: 30px;
  list-style-type: none;
  background-image: url(../images/tick.png);
  background-repeat: no-repeat;
  background-position: left;
  padding-left: 30px;
  font-weight: 400;
  font-family: roboto;
}
.main-banner {
}
.curve {
  display: block;
  position: relative;
}
.curve img {
  width: 100%;
}
.slider1 {
  background-image: url(../images/slider1.png);
  background-size: 40%;
  background-repeat: no-repeat;
  background-position: right;
  height: 400px;
}
.slider2 {
  background-image: url(../images/slider2.png);
  background-size: 40%;
  background-repeat: no-repeat;
  background-position: right;
  height: 400px;
}
.slider3 {
  background-image: url(../images/slider3.png);
  background-size: 40%;
  background-repeat: no-repeat;
  background-position: right;
  height: 400px;
}
.content_slide3 {
  display: block;
  clear: both;
  text-transform: uppercase;
}
.content_slide3 img {
  float: left;
}
.content_slide3 span {
  font-size: 30px;
  font-family: roboto;
  font-weight: bold;
  color: #175480;
  display: block;
  padding-top: 20px;
  line-height: 35px;
}
.content_slide3 p {
  font-size: 15px;
  color: #175480;
  font-family: roboto;
  display: inline-block;
}
.carousel-indicators .active {
  background-color: #ee7222 !important;
}
.carousel-indicators li {
  margin-right: 15px;
  background-color: #175480 !important;
  border: 2px solid #ffffff !important;
  width: 18px !important;
  height: 18px !important;
  border-radius: 100% !important;
  opacity: inherit !important;
  transition: inherit !important;
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.52);
  -webkit-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.52);
  -moz-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.52);
}
